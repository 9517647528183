import request from "@/utils/request";
const test = location.origin.indexOf("test-huanxinxiang.qiumo.net.cn") >= 0;

const ipBaseURL = test
  ? "https://test-latefall-marketing.qiumo.net.cn"
  : "https://latefall-marketing.qiumo.net.cn";
// const ipBaseURL = "https://test-latefall-marketing.qiumo.net.cn";

// 预下单
export function getPreorder(data) {
  return request({
    url: "/latefall-marketing/company/video/pre/order",
    method: "post",
    ipBaseURL: ipBaseURL,
    data,
  });
}
// 下单后回调
export function htmlCallback(data) {
  return request({
    url: "/latefall-marketing/company/video/async/call",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    ipBaseURL: ipBaseURL,
    data,
  });
}
