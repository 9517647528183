<template>
  <van-popup v-model="visibility" class="dialog-confirm">
    <div class="title" v-if="alertType==1">业务规则</div>
    <div class="content" v-if="alertType==1">
      <div class="text">1.企业视频彩铃内容基础版仅支持中国移动用户订购(港澳台用户除外)，
        <span>业务费用10元/月，通过话费扣取，按自然月收取，不按天进行折算。开通业务立即生效，未退订按自然月续订和续费，退订业务立即生效，退订业务的次月起不再扣费。</span></div>
        <div class="text">{{ termText }}</div>
    </div>

    <div class="title" v-if="alertType==2">隐私条款</div>
    <div class="content" v-if="alertType==2">
      <div class="text">{{ privacyText }}</div>
    </div>

    <div class="title" v-if="alertType==3">服务协议</div>
    <div class="content" v-if="alertType==3">
      <div class="text">{{ serveText }}</div>
    </div>
    <div class="footer">
      <img class="footer-btn" @click="close" src="https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/agree.png" alt="">
    </div>
  </van-popup>
</template>

<script>
import { termText ,privacyText ,serveText} from "./../data.js";
export default {
  props: {
    visibility: {
      type: Boolean,
      default: false,
    },
    alertType: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      termText, //1业务规则
      privacyText, //2隐私条款
      serveText, //3服务协议
    };
  },
  methods: {
    close() {
      this.$emit("update:visibility", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog {
  &-confirm {
    box-sizing: border-box;
    width: 720px;
    height: 70%;
    padding: 10px;
    background: #FCF9EE;
    border-radius: 20px;
    .title{
      text-align: center;
      font-size: 40px;
      font-family: Lantinghei SC;
      font-weight: 600;
      color: #5D5D5D;
      line-height: 0.60rem;
      margin-top: 30px;
    }
    .content{
      margin: 0 auto;
      width: 90%;
      height: 70%;
      overflow-y: scroll;
      overflow-x: hidden;
      margin-top: 40px;
      font-weight: normal;
      font-stretch: normal;
      color: #4d2f1c;
      font-size: 26px;
      padding-left: 20px;
      white-space: pre-wrap;
      line-height: 40px;
      .text {
        line-height: 36px;
        letter-spacing: 0px;
        span{
          color: #ffd93e;
        }
      }
    }
    .footer {
      width: 100%;
      height: 150px;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .footer-btn{
        width: 206px;
        height: 87px;
      }

      .btn {
        width: 185px;
      }

      .submit {
        padding-left: 18px;
        width: 292px;
      }
    }
  }
}
</style>
