<template>
  <div class="container">
    <img
      class="top"
      src="https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/backtop.png"
    />

    <div class="form">
      <van-field
        v-model="form.phone"
        type="tel"
        maxlength="11"
        placeholder="请输入手机号码"
      />
      <img
        class="submit"
        @click="onSubmit"
        src="https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/btnOrder.png"
      />

      <div class="checked">
        <van-checkbox
          checked-color="#2e5aad"
          v-model="checked"
          icon-size="14px"
        >
          <div class="checked_info">
            我已阅读并同意:
            <span class="book" @click="dialogInfo(1)">业务规则、</span>
            <span class="book" @click="dialogInfo(2)">隐私条款、</span>
            <span class="book" @click="dialogInfo(3)">服务协议</span>
            等协议，已知悉业务说明并用该手机号登录办理
          </div>
        </van-checkbox>
        <div class="checked_info">业务名称：中国移动企业视频彩铃商务名片</div>
        <div class="checked_info yellow">资费10元/月，次月自动续订</div>
      </div>
    </div>
    <div class="serve">
      <div class="serve-icon">
        <img
          src="https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/applet.png"
          alt=""
        />
      </div>
      <div class="serve-text">{{ ruleText }}</div>
    </div>
    <div class="produce">
      <div class="produce-text">
        1.企业视频彩铃内容基础版仅支持中国移动用户订购(港澳台用户除外)，
        <span
          >业务费用10元/月，通过话费扣取，按自然月收取，不按天进行折算。开通业务立即生效，未退订按自然月续订和续费，退订业务立即生效，退订业务的次月起不再扣费。</span
        >
        ,<br />{{ termText }}
      </div>
    </div>

    <v-dialog :visibility.sync="visibility" :alertType="alertType" />
  </div>
</template>

<script>
import VDialog from "./dialog/index.vue";
import { ruleText, termText } from "./data.js";
import { ZaoMiTang } from "@/utils/common";
import { getPreorder, htmlCallback } from "@/api/zaoMiTang/index.js";
export default {
  data() {
    return {
      form: {
        phone: "",
        productNo: "LP1854370112568725505",
        code: "",
        promotionApp: "",
        channelCode: "LC202412381238",
      },
      checked: false,
      visibility: false,
      ruleText,
      termText,
      alertType: 1, //1业务规则  2隐私条款  3服务协议

      token: "",
      channelCode: "",
      adminMsisdn: "",
      telephone: "",
      productId: "",
      addDepartment: "",
      departmentId: "",
      departmentName: "",
      orderId: "",
      params: "",
    };
  },
  components: {
    VDialog,
  },
  created() {
    const { from = "", promotionApp = "" } = this.$route.query;
    this.form.code = from;
    this.form.promotionApp = promotionApp;
    console.log(this.form.code, this.form.promotionApp);
    ZaoMiTang("https://m.12530.com/order/pub-ui/js/and/migus.js", function () {
      console.log("migus loaded");
      ZaoMiTang(
        "https://m.12530.com/order/pub-ui/js/and/crbtCentralitySDK.js",
        function () {
          console.log("crbtCentralitySDK loaded");
          // 这里可以放置依赖于file1和file2的代码
        }
      );
    });
  },
  mounted() {
    const that = this;
    console.log("navigator.userAgent=", navigator.userAgent);
    // 确保 centralityCallBack 在全局作用域中定义
    window.centralityCallBack = function (result) {
      console.log("Callback result:", result);
      var data = {
        youCallbackName: "orderCallBack",
        channelCode: that.channelCode,
        token: that.token,
        productId: that.productId,
        addDepartment: that.addDepartment,
        departmentName: that.departmentName,
        departmentId: that.departmentId,
        adminMsisdn: that.adminMsisdn,
        excode: "",
        defSeq: that.orderId,
        firstExtendedField: that.telephone, //客服电话
        secondExtendedField: that.token,
        thirdExtendedField: "",
        fourthExtendField: "",
        fifthExtendField: "",
        longestExtendField: "",
      };
      //咪咕平台提交订购参数
      companyVrbtOrder_centrality(data);
      that.params = JSON.stringify(data);
    };
    window.orderCallBack = function (result) {
      console.log("orderCallBack", result);
      that.zmtCallback(result);
    };
  },
  methods: {
    async onSubmit() {
      let filterPhone = /^1[3456789]\d{9}$/;
      const { phone } = this.form;
      if (!filterPhone.test(phone)) {
        this.$toast.fail("请输入正确的手机号码");
      } else if (!this.checked) {
        this.$toast.fail("查看并同意业务规则、隐私条款、服务协议");
      } else {
        this.preOrder();
      }
    },
    // 预下单
    async preOrder() {
      this.$toast({
        type: "loading",
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      const res = await getPreorder({
        phone: this.form.phone,
        productNo: this.form.productNo,
        code: this.form.code,
        channelAccountName: "秋末",
        notifyUrl:
          "https://test-latefall-marketing.qiumo.net.cn/latefall-marketing/zmt/api/notify/test",
        appPackageName: navigator.userAgent,
        promotionApp: this.form.promotionApp,
        channelCode: this.form.channelCode,
      });
      this.$toast.clear();
      if (res.status == 200) {
        this.token = res.data.token;
        this.channelCode = res.data.channelCode;
        this.adminMsisdn = res.data.adminMsisdn;
        this.telephone = res.data.telephone;
        this.productId = res.data.productId;
        this.addDepartment = res.data.addDepartment;
        this.departmentId = res.data.departmentId;
        this.departmentName = res.data.departmentName;
        this.orderId = res.data.videoOrderId;
        console.log(this.channelCode);
        //咪咕的二确初始化方法
        openMiGuInit_centrality(this.channelCode, "centralityCallBack");
      } else {
        this.$toast.fail(res.message);
      }
    },
    // 下单后回调枣米糖接口
    async zmtCallback(result) {
      this.$toast({
        type: "loading",
        message: "加载中...",
        duration: 0,
        forbidClick: true,
      });
      const data = new URLSearchParams();
      data.append("orderId", this.orderId);
      data.append("resCode", result.resCode);
      data.append("resMsg", result.resMsg);
      data.append("params", this.params);
      const res = await htmlCallback(data);
      this.$toast.clear();
      if (res.code == 200) {
        var msg = result.resMsg;
        if (msg === "") {
          this.$toast.success("订购成功");
        } else {
          this.$toast.fail(msg);
        }
      } else {
        this.$toast.fail(res.msg);
      }
    },
    dialogInfo(num) {
      this.visibility = true;
      this.alertType = num;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #b21186;
  padding-bottom: 20px;
  .top,
  .card {
    width: 100%;
  }

  .form {
    height: 500px;
    width: 750px;
    padding-top: 33px;
    box-sizing: border-box;
    margin: -600px 0 40px 0;

    ::v-deep .van-cell {
      width: 640px;
      height: 100px;
      background-color: #ffffff;
      border-radius: 50px;
      margin: 0px auto 0;
      overflow: hidden;
      box-sizing: border-box;
      padding: 0 0 0 23px;

      .van-field__body {
        height: 100%;

        input {
          height: 100%;
        }
      }

      &::after {
        border: none;
      }

      .van-field__control {
        color: #000;
        font-weight: normal;
        &::-webkit-input-placeholder {
          font-size: 34px;
          font-weight: 900;
          color: #757575;
        }
      }
    }

    .submit {
      width: 572px;
      display: block;
      margin: 50px auto 0;
      animation: scaleDraw 2s infinite;
      @keyframes scaleDraw {
        0% {
          transform: scale(1);
        }
        25% {
          transform: scale(0.9);
        }
        50% {
          transform: scale(1);
        }
        75% {
          transform: scale(0.9);
        }
        100% {
          transform: scale(1);
        }
      }
    }

    .checked {
      width: 80%;
      margin: 30px auto 0;
      font-size: 24px;
      font-weight: 400;
      padding: 10px 0 300px;
      text-align: center;

      ::v-deep .van-checkbox {
        width: auto;
        .van-checkbox__icon .van-icon {
          border: 1px solid #2e5aad;
          margin-top: -20px;
        }

        &__label {
          margin-left: 4px;
          color: #fff !important;

          .book {
            color: #ffd93e;
          }
        }
      }
      .checked_info {
        color: #fff;
        font-size: 24px;
      }
      .yellow {
        color: #ffd93e;
        font-weight: 500;
      }
    }
  }
  .serve {
    width: 90%;
    height: 400px;
    margin: 0 auto;
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/followOfficialAccount.png")
      no-repeat center/100%;
    overflow: hidden;
    .serve-icon {
      width: 30%;
      height: 80%;
      margin-top: 70px;
      margin-left: 20px;
      float: left;
      display: flex;
      justify-content: space-around;
      align-items: center;
      img {
        width: 180px;
        height: 180px;
        display: block;
      }
    }
    .serve-text {
      width: 60%;
      height: 80%;
      margin-top: 70px;
      margin-right: 20px;
      float: right;
      white-space: pre-wrap;
      font-size: 26px;
      color: #bfbfbf;
      display: flex;
      justify-content: space-around;
      align-items: center;
      line-height: 36px;
    }
  }
  .produce {
    width: 90%;
    height: 1400px;
    display: flex;
    margin: 0 auto;
    background: url("https://cdn.qiumo.net.cn/allsmarketmaterial/zaomitang/Introduction.png")
      no-repeat center center;
    background-size: 100% 100%;
    overflow: hidden;
    color: #bfbfbf;
    font-size: 26px;
    line-height: 38px;
    white-space: pre-wrap;
    .produce-text {
      width: 90%;
      margin: 100px auto 0;
      span {
        color: #ffd93e;
        font-weight: 800;
      }
    }
  }
}
</style>
