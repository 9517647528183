export const ruleText = `1、截图保存微信小程序码。
2、根据提示提供彩铃定制信息。
3、提供企业/团队成员号码添加至企业/团队（功能费10元/人，话费扣取）即可共享视频彩铃。`

export const termText = `2.订购该业务的用户，可享制作和设置专属的企业视频彩铃业务权益。
3.成功订购业务后，业务权益实时到账至订购业务对应的手机号码
4.如需退订业务，请发送短信0000至10086，根据收到的短信提示操作进行退订或发送短信2至10658831退订。
5.办理发起后，平台会通过短信进行验证码、办理结果通知、客服信息等相关的告知，在发起办理时，即视为您同意接收短信。
6.根据《消费者权益保护法》第二十五条(无理由退货制度)，该业务属于虚拟商品范畴，购买成功后不支持退货、换货。
7.业务平台客服电话:4009954595
8. 未成年人使用条款
8.1 若您未满18周岁，则为未成年人，应在监护人监护、指导下阅读本协议和使用企业视频彩铃内容版服务。
8.2 未成年人用户在使用企业视频彩铃内容版服务时应注意以下事项，提高安全意识，加强自我保护:
(1)填写个人资料时，加强个人保护意识，以免不良分子对个人生活造成骚扰:
(2)在监护人或老师的指导下，学习正确使用网络，认清网络世界与现实世界的区别，避免沉迷于网络，影响日常的学习生活。
8.3监护人应指导子女上网应该注意的安全问题，防患于未然。`
//2隐私条款
export const privacyText = `
本应用非常重视用户隐私政策并严格遵守相关的法律规定。请您仔细阅读《隐私政策》后再继续使用。如果您继续使用我们的服务，表示您已经充分阅读和理解我们协议的全部内容。
本小程序尊重并保护所有使用服务用户的个人隐私权为了给您提供更准确、更优质的服务，本应用会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，本应用不会将这些信息对外披露或向第三方提供。本应用会不时更新本隐私权政策。您在同意本应用服务使用协议之时，即视为您已经同意本隐私权政策全部内容。
1.适用范围
(A) 在您注册本应用小程序帐号时，您根据小程序要求提供的个人注册信息;
(B)在您使用本应用网络服务，或访问本应用平台网页时，本应用自动接收并记录的您的浏览器和计算机上的信息，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据;
(C) 本应用通过合法途径从商业伙伴处取得的用户个人数据。
(D)本应用严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限，予以封号处理。
2.信息使用
(A)本应用不会向任何无关第三方提供、出售、出租分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许本应用消息通知`
//3服务协议
export const serveText = `1.企业视频彩铃内容基础版仅支持中国移动用户订购(港澳台用户除外)，业务费用
10元/月，通过话费扣取，按自然月收取，不按天进行折算。开通业务立即生效，未退订按自然月续订和续费，退订业务的次月起不再扣费。
2.订购该业务的用户，可享制作和设置专属的企业视频彩铃业务权益。
3.成功订购业务后，业务权益实时到账至订购业务对应的手机号码，请关注微信小程序“咪咕视彩号”，制作和设置专属的企业视频彩铃。(注:铃音设置后12小时内生效，请耐心等待。)
4.如需退订业务，请发送短信0000至10086，根据收到的短信提示操作进行退订或发送短信2至10658831退订。`
